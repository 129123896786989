<template>
    <div>
        <EmployeeWeekPlus
            v-for="employee in calendarEvents"
            :key="employee.employee.uuid"
            ref="employeeCalendar"
            :employeeProp="employee.employee"
            :data="data"
        />

        <WeekPerEmployeeFloatingMenu
            @changeCalendarSettings="changeCalendarSettings"
        />
    </div>
</template>
<script>

import queryString from 'query-string';
import { mapState } from 'vuex';

export default {
    components: {
        EmployeeWeekPlus:
        () => import(/* webpackChunkName: "WeekPerEmployeePlusCalendar/EmployeeWeekPlus" */ './components/EmployeeWeekPlus'),
        WeekPerEmployeeFloatingMenu:
        () => import(/* webpackChunkName: "WeekPerEmployeePlusCalendar/WeekPerEmployeeFloatingMenu" */ './components/WeekPerEmployeeFloatingMenu'),
    },

    data() {
        return {
            calendarEvents: [],
            from:           this.$dayjs(),
            to:             this.$dayjs().add(7, 'day'),
            data:           null,
        };
    },

    computed: {
        ...mapState('calendar', {
            incrementDate: state => state.incrementDate,
            decrementDate: state => state.decrementDate,
        }),
    },

    watch: {
        incrementDate() {
            this.from = this.from.add(7, 'day');
            this.to = this.to.add(7, 'day');
            this.getEvents();
        },
        decrementDate() {
            this.from = this.from.subtract(7, 'day');
            this.to = this.to.subtract(7, 'day');
            this.getEvents();
        },
    },

    created() {
        this.getEvents();
    },

    methods: {
        async getEvents() {
            const query = queryString.parse(window.location.search, { sort: false });
            // @Filip
            query.employee = Array.isArray(query.employee) ? query.employee : (query.employee ? [query.employee] : []);

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/week_per_employee`, {
                params: {
                    from:      this.from.valueOf(),
                    to:        this.to.valueOf(),
                    cancelled: this.$store.state.calendar.filterCancelled ? 1 : 0,
                    // employees_uuid:             query.employee,
                },
            });

            this.$store.commit('calendar/updateWeekEventsData', data);

            this.calendarEvents = data;
        },

        changeCalendarSettings(event) {
            this.data = event;
        },
    },
};
</script>
